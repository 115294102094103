<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12">
        <h1 class="font-weight-bold mb-1">Recent Payments</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-3">
          <b-table-simple class="mb-0">
            <b-thead class="border-top-none">
              <b-tr>
                <b-th>Invoice ID</b-th>
                <b-th>Order ID</b-th>
                <b-th class="text-center">Amount</b-th>
                <b-th class="text-center">Payment Date</b-th>
                <b-th class="text-right">Method</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="!isLoading">
              <b-tr v-for="payment in data" :key="`payment${payment.id}`">
                <b-td><router-link variant="secondary" :to="{name: 'dash.invoices.update', params: { id: payment.id }}">#{{ payment.id }}</router-link></b-td>
                <b-td><router-link variant="secondary" :to="{name: 'dash.orders.view', params: { id: payment.order_id }}">#{{ payment.order_id }} - {{ payment.order.customer.forename }} {{ payment.order.customer.surname }}</router-link></b-td>
                <b-td class="text-center">{{ payment.amount | currencyFormat }}</b-td>
                <b-td class="text-center">{{ moment(payment.paid_at).format('DD/MM/YYYY') }}</b-td>
                <b-td class="text-right">{{ payment.payment_method }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-if="isLoading">
              <b-tr>
                <b-td colspan="5" class="text-center"><b-spinner variant="secondary"></b-spinner></b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="data && !isLoading && pagination.per < pagination.total">
      <b-col cols="12">
        <b-pagination @change="onPaginate" :per-page="pagination.per" :total-rows="pagination.total" v-model="pagination.current"></b-pagination>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'
import momentMixin from '../../mixins/momentMixin'

export default {
  mixins: [currentUser, momentMixin],
  created () {
    this.fetchManyRecent(this.$route.query)
  },
  data: () => ({
    
  }),
  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('invoices', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('invoices', ['batchDelete', 'fetchManyRecent']),
    async onDeleteMany ({ ids, modalId }) {
      await this.batchDelete({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchManyRecent(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchManyRecent({ ...this.$route.query, page })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchManyRecent({ ...this.$route.query, query })
      }
    },
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>

.border-top-none {
  border-top: hidden;
}

</style>
