<template>
  <resource-search @delete="() => this.$emit('delete')"  @input="id => this.$emit('input', id)" module="orders" :show-delete="showDelete" :error-feed="errorFeed" :error-key="errorKey" :wait-for-input="waitForInput" :key="value.id" :fetch-params="extraFetchParams" v-model="value">
    <template #popover-list="{ result }">
      <p class="font-weight-bold mb-0  w-100-m-1">{{ result.customer.forename }} {{ result.customer.surname }}</p>
      <p class="text-muted text-truncate mb-0 w-100-m-1"><small>{{ result.customer.email }}</small></p>
      <p class="text-muted mb-0" v-if="showRemainingAmount"><small>Remaining: {{ result.remaining_amount | currencyFormat }}</small></p>
      <p class="text-muted mb-0" v-if="showRemainingAmount && result.finance_package_id"><small>Remaining After Finance: {{ result.remaining_amount_after_finance | currencyFormat }}</small></p>
    </template>

    <template #selected="{ selected }">
      <p class="font-weight-bold mb-0  w-100-m-1">{{ selected.customer.forename }} {{ selected.customer.surname }}</p>
      <p class="text-muted text-truncate mb-0 w-100-m-1"><small>{{ selected.customer.email }}</small></p>
      <p class="text-muted mb-0" v-if="showRemainingAmount"><small>Remaining: {{ selected.remaining_amount | currencyFormat }}</small></p>
      <p class="text-muted mb-0" v-if="showRemainingAmount && selected.finance_package_id"><small>Remaining After Finance: {{ selected.remaining_amount_after_finance | currencyFormat }}</small></p>
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'
import momentMixin from '../mixins/momentMixin'

export default {
  mixins: [momentMixin],
  components: { ResourceSearch },
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    showRemainingAmount: {
      type: Boolean,
      default: false,
    },
    waitForInput: {
      type: Boolean,
      default: true,
    },
    errorFeed: Object,
    errorKey: String,
    type: String,
    value: Object,
  },
  computed: {
    extraFetchParams() {
      var returnParams = {};

      if(this.type) {
        returnParams = { ...returnParams, type: this.type };
      }

      return returnParams;
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>

</style>