<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Invoice</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-column flex-md-row justify-content-between">
                  <div class="d-flex flex-column flex-fill">
                    <p class="h3 font-weight-bold text-muted">Assign an Order</p>
                    <p class="mb-0 text-muted">What order is this invoice for?</p>
                  </div>
                  <div class="d-flex flex-column justify-content-center mt-2 mt-md-0">
                    <order-search :error-feed="errors.errors" :error-key="'order_id'" :input-state="validationState(errors.errors, 'order_id')" class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100" type="order" :show-remaining-amount="true" @input="orderUpdated" v-model="invoice.order" />
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Invoice Due Date" :invalid-feedback="validationInvalidFeedback(errors.errors, 'invoice_due')">
                <b-form-datepicker :state="validationState(errors.errors, 'invoice_due')" v-model="invoice.invoice_due" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Amount" :state="validationState(errors.errors, 'amount')" :invalid-feedback="validationInvalidFeedback(errors.errors, 'amount')">
                <b-input-group prepend="£">
                  <b-form-input :state="validationState(errors.errors, 'amount')" v-model="invoice.amount"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col class="d-flex flex-column justify-content-end" cols="12" md="4">
              <b-card class="mb-3">
                <b-checkbox v-model="invoice.paid">Invoice Paid?</b-checkbox>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" v-if="invoice.paid">
              <b-form-group label="Payment Method" :invalid-feedback="validationInvalidFeedback(errors.errors, 'payment_method')">
                <b-form-select v-model="invoice.payment_method" :state="validationState(errors.errors, 'payment_method')">
                  <b-form-select-option disabled :value="null">Please select a payment method</b-form-select-option>
                  <b-form-select-option value="Manual">Manual</b-form-select-option>
                  <b-form-select-option value="Bank Transfer">Bank Transfer</b-form-select-option>
                  <b-form-select-option value="Card Payment">Card Payment</b-form-select-option>
                  <b-form-select-option value="Finance">Finance</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="invoice.payment_url">
            <b-col cols="12" md="9">
              <b-form-group label="Payment URL">
                <b-form-input disabled v-model="invoice.payment_url"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col class="d-flex flex-column justify-content-end" cols="12" md="3">
              <b-button class="mb-3" :disabled="!invoice.payment_url" variant="dark">Copy URL</b-button>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import momentMixin from '../../mixins/momentMixin'
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import OrderSearch from '../../components/OrderSearch';

export default {
  mixins: [momentMixin, validation],
  components: { OrderSearch },
  created () {
    if (this.$route.params.order_id) {
      this.invoice.order.id = this.$route.params.order_id;
      this.invoice.order_id = this.$route.params.order_id;
    }else if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters('invoices', ['errors', 'isLoading', 'isSaving', 'single']),
  },
  data: () => ({
    activityLog: [],
    invoice: {
      id: null,
      order_id: null,
      order: { id: null },
      amount: null,
      payment_url: null,
      invoice_due: null,
      payment_method: null,
      paid: false,
    }
  }),
  methods: {
    ...mapActions('invoices', ['fetch', 'save']),
    submit () {
      let data = {
        id: this.invoice.id,
        order_id: this.invoice.order_id,
        amount: this.invoice.amount,
        payment_url: this.invoice.payment_url,
        invoice_due: this.invoice.invoice_due,
        payment_method: this.invoice.payment_method,
        paid: this.invoice.paid,
      }

      this.save(data).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.invoices.list',
          params: {
            notifications: [{
              variant: 'success',
              title: `Invoice #${response.data.id}`,
              subtitle: `${response.data.paid ? 'Paid' : 'Unpaid'}`,
              text: `The invoice has been raised for order number ${response.data.order_id}`,
              timeout: 2500
            }]
          }
        })
      })
    },

    orderUpdated() {
      this.invoice.order_id = this.invoice.order.id;
    },
  },
  watch: {
    single (newUser) {
      this.invoice = this.single
      this.invoice.paid = this.invoice.paid ? true : false
    }
  }
}
</script>

<style>

</style>
