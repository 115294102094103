<template>
  <router-view />
</template>

<script>
import invoiceModule from '../../store/invoices'
import orderModule from '../../store/orders'

export default {
  beforeCreate () {
    this.$store.registerModule('invoices', invoiceModule)
    this.$store.registerModule('orders', orderModule)
  },
  destroyed () {
    this.$store.unregisterModule('invoices')
    this.$store.unregisterModule('orders')
  }
}
</script>
