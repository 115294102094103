import {
  INVOICES_BATCH_DELETE_REQUEST,
  INVOICES_BATCH_DELETE_SUCCESS,
  INVOICES_BATCH_DELETE_FAILURE,
  INVOICES_FETCH_MANY_REQUEST,
  INVOICES_FETCH_MANY_SUCCESS,
  INVOICES_FETCH_MANY_FAILURE,
  INVOICES_FETCH_REQUEST,
  INVOICES_FETCH_SUCCESS,
  INVOICES_FETCH_FAILURE,
  INVOICES_SAVE_REQUEST,
  INVOICES_SAVE_SUCCESS,
  INVOICES_SAVE_FAILURE,

  EMPTY_INVOICES,
  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(INVOICES_BATCH_DELETE_REQUEST)
    return window.axios.post('/invoices', { _method: 'DELETE', ids }).then(response => {
      commit(INVOICES_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INVOICES_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1, invoiceStatus = 'All', installationsFrom = null, installationsTo = null }) {
    commit(INVOICES_FETCH_MANY_REQUEST)
    return window.axios.get('/invoices', { params: { query, page, invoiceStatus, installationsFrom, installationsTo} }).then(response => {
      commit(INVOICES_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INVOICES_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchManyRecent ({ commit }, { query = '', page = 1 }) {
    commit(INVOICES_FETCH_MANY_REQUEST)
    return window.axios.get('/invoices/recent', { params: { query, page } }).then(response => {
      commit(INVOICES_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INVOICES_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(INVOICES_FETCH_REQUEST)
    return window.axios.get(`/invoices/${id}`).then(response => {
      commit(INVOICES_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INVOICES_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, invoice) {
    const path = invoice.id ? `/invoices/${invoice.id}` : '/invoices'
    const method = invoice.id ? 'put' : 'post'

    commit(INVOICES_SAVE_REQUEST)
    return window.axios[method](path, invoice).then(response => {
      commit(INVOICES_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(INVOICES_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(INVOICES_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_INVOICES);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
