<template>
  <b-container fluid>
    <ResourceList
      create-to="dash.invoices.create" :initial-filter-collapse="filtersCollapsed" resource="invoice" soft-deletes title="Invoices" :has-filters="true" :has-buttons="false"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination" :can-select-many="false"
      :search-query="$route.query.query" @paginate="onPaginate"
      @search="onSearch">

      <template #filters>
        <b-row>
          <b-col cols="12">
              <b-form-group label="Invoice Status">
                <b-form-select v-model="invoiceStatus" @change="onPaginate" :options="[
                  { value: 'All', text: 'All' },
                  { value: 'Paid', text: 'Paid' },
                  { value: 'Unpaid', text: 'Unpaid' },
                ]"/>
              </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
              <b-form-group label="Installations From">
                <b-form-datepicker v-model="installationsFrom" @input="onPaginate"></b-form-datepicker>
              </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
              <b-form-group label="Installations To">
                <b-form-datepicker v-model="installationsTo" @input="onPaginate"></b-form-datepicker>
              </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill p-3">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary">
              <b-icon class="mr-1" :icon="'file-earmark'" />
              <router-link class="text-dark" :to="{name: 'dash.invoices.update', params: { id: item.id }}">#{{ item.id }} {{ [item.order.customer.forename, item.order.customer.surname].join(' ') }}</router-link>
            </p>
            <p class="align-self-end mb-1 text-muted"><FriendlyDate :date="item.created_at" /></p>
          </div>
          <p class="mb-0"><b-badge :variant="item.paid ? 'success' : 'danger'">{{ item.paid ? 'Paid' : 'Unpaid' }}</b-badge> invoice with a due amount of {{ item.amount | currencyFormat }}</p>
          <p class="mb-0 mb-md-1 d-flex flex-row">This invoice {{ moment().isBefore(item.invoice_due) ? 'is' : 'was' }} due <FriendlyDate class="align-self-end mb-1 ml-1 text-muted" :date="item.invoice_due" :omit-time="true"></FriendlyDate>.</p>
          <div v-if="installDates(item.order).length">
            <p class="mb-1 mb-md-1" v-for="(date, dateInd) in installDates(item.order)" :key="`date${date}${dateInd}`">Installation Date: {{ moment(date).format('DD/MM/YYYY') }}.</p>
          </div>
          <div class="d-none d-md-flex flex-row">
            <a :href="'/pdf/invoice/'+item.id" target="_blank" class="btn btn-primary btn-sm mr-1 mb-0" size="sm" v-b-popover.hover.bottom="'View the PDF version of the invoice.'"><b-icon icon="file-text" /> View PDF</a>
            <b-button class="mr-1 mb-0" size="sm" variant="secondary" :to="{name: 'dash.orders.view', params: { id: item.order_id }}" v-b-popover.hover.bottom="'Go straight to the order that is assigned to this invoice.'"><b-icon icon="box-seam" /> View Order</b-button>
            <b-button v-if="!item.paid" @click="sendInvoiceReminder(item.id)" class="mr-1 mb-0" size="sm" variant="dark" v-b-popover.hover.bottom="'Sends a reminder email and SMS to the customer with this invoice.'"><b-icon icon="envelope-open" /> Send Reminder</b-button>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'
import momentMixin from '../../mixins/momentMixin'

export default {
  mixins: [currentUser, momentMixin],
  beforeMount() {
    if(localStorage.getItem('invoiceStatus') || localStorage.getItem('installationsFrom') || localStorage.getItem('installationsTo')) {
      this.invoiceStatus = localStorage.getItem('invoiceStatus')
      this.installationsFrom = localStorage.getItem('installationsFrom')
      this.installationsTo = localStorage.getItem('installationsTo')
      this.filtersCollapsed = false
    }
  },
  mounted () {
    this.fetchMany({
      ...this.$route.query,
      invoiceStatus: this.invoiceStatus,
      installationsFrom: this.installationsFrom,
      installationsTo: this.installationsTo
    })
  },
  data: () => ({
    invoiceStatus: 'All',
    installationsFrom: null,
    installationsTo: null,

    filtersCollapsed: true,
  }),
  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('invoices', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('invoices', ['batchDelete', 'fetchMany']),
    async onDeleteMany ({ ids, modalId }) {
      await this.batchDelete({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchMany({ ...this.$route.query, page, invoiceStatus: this.invoiceStatus, installationsFrom: this.installationsFrom, installationsTo: this.installationsTo })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchMany({ ...this.$route.query, query, invoiceStatus: this.invoiceStatus, installationsFrom: this.installationsFrom, installationsTo: this.installationsTo })
      }
    },
    sendInvoiceReminder(id) {
      window.axios.get(`/invoices/send-reminder/${id}`).then(response => {
        this.$root.$emit('card-notification', {
          variant: 'success',
          title: `Invoice Reminder Sent`,
          subtitle: `Invoice #${response.data.id}`,
          timeout: 2500
        })
      })
    },

    installDates(order) {
      var installDates = []
      order.items.forEach(orderItem => {
        if(orderItem.install_required && orderItem.install_at) installDates.push(orderItem.install_at)
      })
      return installDates
    },
  },
  watch: {
    invoiceStatus(newVal) {
      localStorage.setItem('invoiceStatus', newVal)
    },
    installationsFrom(newVal) {
      console.log(newVal)
      localStorage.setItem('installationsFrom', newVal)
    },
    installationsTo(newVal) {
      localStorage.setItem('installationsTo', newVal)
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
        style: 'currency', currency: 'GBP'
    }).format(v),
  }
}
</script>

<style>

</style>
