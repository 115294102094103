export const ORDERS_BATCH_DELETE_REQUEST = 'ORDERS_BATCH_DELETE_REQUEST'
export const ORDERS_BATCH_DELETE_SUCCESS = 'ORDERS_BATCH_DELETE_SUCCESS'
export const ORDERS_BATCH_DELETE_FAILURE = 'ORDERS_BATCH_DELETE_FAILURE'

export const ORDERS_FETCH_MANY_REQUEST = 'ORDERS_FETCH_MANY_REQUEST'
export const ORDERS_FETCH_MANY_SUCCESS = 'ORDERS_FETCH_MANY_SUCCESS'
export const ORDERS_FETCH_MANY_FAILURE = 'ORDERS_FETCH_MANY_FAILURE'

export const ORDERS_FETCH_MANY_NO_PAGINATE_REQUEST = 'ORDERS_FETCH_MANY_NO_PAGINATE_REQUEST'
export const ORDERS_FETCH_MANY_NO_PAGINATE_SUCCESS = 'ORDERS_FETCH_MANY_NO_PAGINATE_SUCCESS'
export const ORDERS_FETCH_MANY_NO_PAGINATE_FAILURE = 'ORDERS_FETCH_MANY_NO_PAGINATE_FAILURE'

export const ORDERS_FETCH_REQUEST = 'ORDERS_FETCH_REQUEST'
export const ORDERS_FETCH_SUCCESS = 'ORDERS_FETCH_SUCCESS'
export const ORDERS_FETCH_FAILURE = 'ORDERS_FETCH_FAILURE'

export const ORDERS_SAVE_REQUEST = 'ORDERS_SAVE_REQUEST'
export const ORDERS_SAVE_SUCCESS = 'ORDERS_SAVE_SUCCESS'
export const ORDERS_SAVE_FAILURE = 'ORDERS_SAVE_FAILURE'

export const ORDERS_CALENDAR_REQUEST = 'ORDERS_CALENDAR_REQUEST'
export const ORDERS_CALENDAR_SUCCESS = 'ORDERS_CALENDAR_SUCCESS'
export const ORDERS_CALENDAR_FAILURE = 'ORDERS_CALENDAR_FAILURE'

export const EMPTY_ORDERS = 'EMPTY_ORDERS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
