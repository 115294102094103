import {
  ORDERS_BATCH_DELETE_REQUEST,
  ORDERS_BATCH_DELETE_SUCCESS,
  ORDERS_BATCH_DELETE_FAILURE,
  ORDERS_FETCH_MANY_REQUEST,
  ORDERS_FETCH_MANY_SUCCESS,
  ORDERS_FETCH_MANY_FAILURE,

  ORDERS_FETCH_MANY_NO_PAGINATE_REQUEST,
  ORDERS_FETCH_MANY_NO_PAGINATE_SUCCESS,
  ORDERS_FETCH_MANY_NO_PAGINATE_FAILURE,

  ORDERS_FETCH_REQUEST,
  ORDERS_FETCH_SUCCESS,
  ORDERS_FETCH_FAILURE,
  
  ORDERS_SAVE_REQUEST,
  ORDERS_SAVE_SUCCESS,
  ORDERS_SAVE_FAILURE,

  ORDERS_CALENDAR_REQUEST,
  ORDERS_CALENDAR_SUCCESS,
  ORDERS_CALENDAR_FAILURE,

  EMPTY_ORDERS,
  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(ORDERS_BATCH_DELETE_REQUEST)
    return window.axios.post('/orders', { _method: 'DELETE', ids }).then(response => {
      commit(ORDERS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ORDERS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1, orderStatus = 'All' }) {
    commit(ORDERS_FETCH_MANY_REQUEST)
    return window.axios.get('/orders', { params: { query, page, orderStatus } }).then(response => {
      commit(ORDERS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ORDERS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchDeliveringSoon ({ commit }, { query = '' }) {
    commit(ORDERS_FETCH_MANY_NO_PAGINATE_REQUEST)
    return window.axios.get('/orders/delivering-soon', { params: { query } }).then(response => {
      commit(ORDERS_FETCH_MANY_NO_PAGINATE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ORDERS_FETCH_MANY_NO_PAGINATE_FAILURE, error)
      throw error
    })
  },
  fetchManyFinance ({ commit }, { query = '', page = 1, orderStatus = 'All' }) {
    commit(ORDERS_FETCH_MANY_REQUEST)
    return window.axios.get('/orders/finance', { params: { query, page, orderStatus } }).then(response => {
      commit(ORDERS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ORDERS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchManyUnsigned ({ commit }, { query = '', page = 1, orderStatus = 'All' }) {
    commit(ORDERS_FETCH_MANY_REQUEST)
    return window.axios.get('/orders/unsigned', { params: { query, page, orderStatus } }).then(response => {
      commit(ORDERS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ORDERS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchManyCalendar ({ commit }, { start_date, end_date }) {
    commit(ORDERS_CALENDAR_REQUEST)
    return window.axios.get('/orders/calendar', { params: { start_date, end_date } }).then(response => {
      commit(ORDERS_CALENDAR_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ORDERS_CALENDAR_FAILURE, error)
      throw error
    })
  },
  fetchSalesMap({ commit }, { from = null, to = null }) {
    commit(ORDERS_FETCH_MANY_NO_PAGINATE_REQUEST)
    return window.axios.get('/orders/sales-map', { params: { from, to } }).then(response => {
      commit(ORDERS_FETCH_MANY_NO_PAGINATE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ORDERS_FETCH_MANY_NO_PAGINATE_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(ORDERS_FETCH_REQUEST)
    return window.axios.get(`/orders/${id}`).then(response => {
      commit(ORDERS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ORDERS_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, order) {
    const path = order.id ? `/orders/${order.id}` : '/orders'
    const method = order.id ? 'put' : 'post'

    commit(ORDERS_SAVE_REQUEST)
    return window.axios[method](path, order).then(response => {
      commit(ORDERS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(ORDERS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(ORDERS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_ORDERS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
