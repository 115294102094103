<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12">
        <h1 class="font-weight-bold mb-1">Delivering Soon</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-3">
          <b-table-simple class="mb-0" responsive>
            <b-thead class="border-top-none">
              <b-tr>
                <b-th>Order ID</b-th>
                <b-th>Order Items</b-th>
                <b-th class="text-center">Install Date</b-th>
                <b-th class="text-center">Order Total</b-th>
                <b-th class="text-center">Paid</b-th>
                <b-th class="text-center">Pending Payment</b-th>
                <b-th class="text-right">Remaining Amount</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="!isLoading">
              <b-tr v-for="order in data" :key="`order${order.id}`" :variant="order.paid ? 'custom-success' : 'custom-danger'">
                <b-td>
                  <div class="d-flex flex-column text-light">
                    <router-link class="text-light" variant="secondary" :to="{name: 'dash.orders.view', params: { id: order.id }}">#{{ order.id }} - {{ order.customer.forename }} {{ order.customer.surname }}</router-link>
                    <small v-if="order.finance_package_id">Finance Package: {{ order.finance_package.name }}</small>
                    <small v-else>Cash Purchase</small>
                  </div>
                </b-td>
                <b-td class="text-light">
                  <p v-for="item in order.items" :key="`item${item.id}`">
                    <small>
                      {{ item.product.name }}
                    </small>
                    <small v-if="supplyData(item)"><strong>{{ supplyData(item) }}</strong></small>
                    <small class="d-block" v-for="extra in item.extras" :key="`extra${extra.id}`"> - {{ extra.name }}</small>
                  </p>
                </b-td>
                <b-td class="text-center text-light">{{ moment(order.items[0].install_at).format('DD/MM/YYYY') }}</b-td>
                <b-td class="text-center text-light">{{ order.total_price | currencyFormat }}</b-td>
                <b-td class="text-center text-light">{{ order.invoices_paid_total | currencyFormat }}</b-td>
                <b-td class="text-center text-light">{{ order.invoices_pending_total | currencyFormat }}</b-td>
                <b-td class="text-right text-light">{{ order.remaining_amount | currencyFormat }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-if="isLoading">
              <b-tr>
                <b-td colspan="7" class="text-center"><b-spinner variant="secondary"></b-spinner></b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'
import momentMixin from '../../mixins/momentMixin'

export default {
  mixins: [currentUser, momentMixin],
  created () {
    this.fetchDeliveringSoon(this.$route.query)
  },
  data: () => ({
    
  }),
  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('orders', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('orders', ['batchDelete', 'fetchDeliveringSoon']),
    async onDeleteMany ({ ids, modalId }) {
      await this.batchDelete({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchDeliveringSoon(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchDeliveringSoon({ ...this.$route.query, page })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchDeliveringSoon({ ...this.$route.query, query })
      }
    },

    supplyData(item) {
      if(item.supply_product) {
        if(item.supply_product.arrived) {
          return 'Supply Assigned'
        }else{
          return 'Awaiting Arrival'
        }
      }
      return false
    },
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style lang="scss">

.border-top-none {
  border-top: hidden;
}

.table-custom-success {
  background-color: #28a745!important;
}

.table-custom-danger {
  background-color: #dc3545!important;
}

</style>
