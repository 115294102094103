export const INVOICES_BATCH_DELETE_REQUEST = 'INVOICES_BATCH_DELETE_REQUEST'
export const INVOICES_BATCH_DELETE_SUCCESS = 'INVOICES_BATCH_DELETE_SUCCESS'
export const INVOICES_BATCH_DELETE_FAILURE = 'INVOICES_BATCH_DELETE_FAILURE'

export const INVOICES_FETCH_MANY_REQUEST = 'INVOICES_FETCH_MANY_REQUEST'
export const INVOICES_FETCH_MANY_SUCCESS = 'INVOICES_FETCH_MANY_SUCCESS'
export const INVOICES_FETCH_MANY_FAILURE = 'INVOICES_FETCH_MANY_FAILURE'

export const INVOICES_FETCH_REQUEST = 'INVOICES_FETCH_REQUEST'
export const INVOICES_FETCH_SUCCESS = 'INVOICES_FETCH_SUCCESS'
export const INVOICES_FETCH_FAILURE = 'INVOICES_FETCH_FAILURE'

export const INVOICES_SAVE_REQUEST = 'INVOICES_SAVE_REQUEST'
export const INVOICES_SAVE_SUCCESS = 'INVOICES_SAVE_SUCCESS'
export const INVOICES_SAVE_FAILURE = 'INVOICES_SAVE_FAILURE'

export const EMPTY_INVOICES = 'EMPTY_INVOICES'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
